<template>
  <div class="content row">
    <div class=" col-sm-12">

      <h1 class="tulum-party-title" style="letter-spacing: .2rem">TULU<span style="color: #879292;text-shadow: #00f2c3 3px 2px 1px;">MTV</span></h1>
      <p style="color: #00f2c3 !important;font-weight: bold;font-size: 11px;margin-top: -30px"> > Videos, Music & Reviews.</p>
      <br>
      <p>
        <strong>Watch, Read & Listen</strong> all about Tulum. Discover the magic of this enchanting destination as we bring you a collection of Carefully curated videos, articles, and music that capture the magical essence of Tulum Today.
      </p>
      <div>
        <router-link :to="{name: 'Artist', params: {slug: video.slug} }" style="font-size: 1rem;margin: 0.7rem;">
          <div v-if="video.metadata.videoid" style="min-height: 300px">
            <video-card :video-id="video.metadata.videoid"
                        width="100%"
                        style="padding: 10px;"
                        mtv
                        height="240px"
                        :title="video.metadata.videotitle"
                        :caption="video.metadata.videocaption">
            </video-card>
          </div>
        </router-link>
      </div>
        <h4 class="tulum-party-subtitle text-smart">Featured Videos</h4>
      <div class="row"  style="min-height: 200px">
        <div v-for="(card, idx) in featured" :key="card.title" class="col-sm-4" >
          <DynamicCard :slug="card.slug" notitle :key="idx"></DynamicCard>
        </div>
      </div>
        <h4 class="tulum-party-subtitle text-smart">You may be interested</h4>
      <div class="row" style="min-height: 200px">
        <div v-for="(card, idx) in suggestions" :key="card.title" class="col-sm-4" style="min-height: 200px">
          <DynamicCard :slug="card.slug" notitle :key="idx"></DynamicCard>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VideoCard from "@/components/Cards/VideoCard";
import DynamicCard from "@/components/Cards/DynamicCard";

export default {
  name: 'Tulumtv',
  metaInfo() {
    return {
      title: `TULUMTV - Videos, Reviews, Dj Sets & More`,
      meta: [
        {
          name: 'description',
          content: `Tulum Videos, Articles & Reviews`,
        },
        {
          name: 'keywords',
          content: `Videos, Reviews, Dj Sets, Clubs, News`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
    };
  },
  components: {
    DynamicCard,
    VideoCard,
  },
  created: function () {
    this.$store.dispatch('getTulumTv', {start: 0});
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    video: {
      get() {
        return this.$store.state.tulumVideos.artist;
      },
    },
    featured: {
      get() {
        return this.$store.state.tulumVideos.featured;
      },
    },
    suggestions: {
      get() {
        return this.$store.state.tulumVideos.suggestions;
      },
    },

  }
}
</script>

